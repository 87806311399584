import React, { Component, cloneElement } from 'react'
import { string, func } from 'prop-types'
import { Transition } from 'react-transition-group'
// import QrCode from 'qrcode.react'
import { Box } from 'grid-styled'
import Button from '../Button/Button'

import {
  Content,
  ModalSimpleContainer,
  ModalContainer,
  ModalContent,
  ModalDescription,
  ModalTitle,
  Overlay,
  Wrapper,
  SummaryContainer,
  SummaryItem,
  ItemTitle,
  ItemValue,
  ButtonsContainer
  // QrCodeWrapper,
  // QrCodeDescription
} from './styles'

// import data from './data.json'

const Fade = ({ isOpen, children }) => (
  <Transition in={isOpen} timeout={400}>
    {state => cloneElement(children, { state })}
  </Transition>
)

class Modal extends Component {
  state = { isOpen: true, loading: false, requested: false }

  close = () => {
    this.setState({ isOpen: !this.state.isOpen })
    setTimeout(() => {
      this.props.hideModal()
    }, 400)
  }

  onSuccessChangesClick = () => {
    this.props.onSuccess()
    this.close()
  }

  onConfirmClick = async () => {
    try {
      this.setLoading(true)
      if (!this.props.dadosValidos) {
        throw new Error("Dados inválidos fornecidos.");
      }
      this.setLoading(true)
      await this.props.onConfirm()
      this.setState({ loading: false, requested: true });
    } catch (error) {
      this.setState({loading: false });
      console.error("Erro ao confirmar:", error.message);
      alert("Erro: Solicitação inválida. Verifique os dados e tente novamente.");
    }
  }

  onConfirmClickDebito = async () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'event',
      eventCategory: 'veloe:area-logado:dados-plano',
      eventAction: 'clique:botao:confirmar:debito',
      eventLabel: 'confirmar:[[sucesso]]'
    })

    try {
      this.setLoading(true)
      await this.props.onConfirm()
      this.setState({ loading: false, requested: true })
    } catch (error) {
      this.setState({loading: false})
      console.error('Erro ao confirmar débito:', error.message);
      alert('Erro: Não foi possível concluir a solicitação. Verifique os dados.');
    }
  }

  setLoading = state => {
    this.setState({ loading: state })
  }

  parseFinalNumber = number => {
    if (!number) return ''
    if (number.toString().length > 4) return number.toString().substr(-4)
    return number
  }

  generateQrCodeValue = () => {
    const { userInfo, convenio } = this.props
    return JSON.stringify({
      TRANSACAO: 'INCLUSAO DE AUTORIZACAO DE DEBITO AUTOMATICO',
      VERSAO: '1',
      BANCOS: [
        {
          BANCO: '001',
          PARAMETROS: {
            CONVENIO: convenio || '',
            IDENTIFICADOR: userInfo.profile.customerId
          }
        }
      ]
    })
  }

  render() {
    const {
      compact,
      background,
      modalBlue,
      maxWidth,
      nextPaymentData,
      currentPaymentType
    } = this.props
    const { isOpen, loading, requested } = this.state
    // 1 is the BB bank code
    const hasCurrPaymentType = !!currentPaymentType
    const isNextInvoiceOpen = nextPaymentData && !!nextPaymentData.faturaAberta
    const isNextPaymentCaja = nextPaymentData && nextPaymentData.paymentMethod === 'CAJA'
    const isNextPaymentCuenta = nextPaymentData && nextPaymentData.paymentMethod === 'CUENTA'
    const isNextPaymentDebitCard =
      nextPaymentData && nextPaymentData.paymentMethod === 'CARTAO_DEBITO'

    // const isBBBank = nextPaymentData.bankCode === '001' && isNextPaymentCuenta
    return (
      <Fade isOpen={isOpen}>
        <Wrapper>
          <Fade isOpen={isOpen}>
            <Content maxWidth={maxWidth || '540'}>
              {requested ? (
                <ModalContainer>
                  <ModalContent center noPadding>
                    <Box mb={['.5rem', compact ? '1.5rem' : '2.125rem']}>
                      <ModalTitle compact={compact}>
                        A sua solicitação foi realizada com sucesso!
                      </ModalTitle>
                      {isNextPaymentCuenta && !nextPaymentData.mesAlteracao && (
                        <ModalDescription top={12}>
                          As alterações serão aplicadas em breve ;)
                        </ModalDescription>
                      )}
                    </Box>
                    {/* {isBBBank && (
                      <QrCodeWrapper my={['2rem']}>
                        <QrCodeDescription>
                          Escaneie com o aplicativo do Banco do Brasil para autorizar o seu débito
                          em conta
                        </QrCodeDescription>
                        <Box>
                          <QrCode height={200} size={200} value={this.generateQrCodeValue()} />
                        </Box>
                      </QrCodeWrapper>
                    )} */}
                    <Button text="Ok" onClick={() => this.onSuccessChangesClick()} />
                  </ModalContent>
                </ModalContainer>
              ) : (
                <ModalContainer background={background}>
                  <ModalContent noPadding>
                    <Box mb={['1.188rem', compact ? '1.5rem' : '3.125rem']}>
                      <ModalTitle compact={compact}>
                        {isNextInvoiceOpen ||
                        isNextPaymentCaja ||
                        isNextPaymentDebitCard ||
                        !hasCurrPaymentType
                          ? 'Os seus dados de pagamento serão alterados em breve'
                          : 'A sua fatura atual já está fechada'}
                      </ModalTitle>
                    </Box>

                    <Box mb={['1.5rem', '1.5rem']}>
                      {hasCurrPaymentType &&
                        nextPaymentData &&
                        ((currentPaymentType &&
                          currentPaymentType !== ('CARTAO_DE_CREDITO' || 'CARTAO_DEBITO')) ||
                          !isNextPaymentCaja ||
                          !isNextPaymentDebitCard) && (
                          <ModalDescription>
                            As alterações estão previstas para a fatura com vencimento no mês de{' '}
                            {nextPaymentData.mesAlteracao}.
                          </ModalDescription>
                        )}
                      <br />
                      <ModalDescription bold>Alteração para:</ModalDescription>
                    </Box>

                    {isNextPaymentCaja || isNextPaymentDebitCard ? (
                      <SummaryContainer>
                        <SummaryItem>
                          <ItemTitle>Forma de pagamento</ItemTitle>
                          <ItemValue>
                            {isNextPaymentCaja ? 'Cartão de crédito' : 'Cartão de Débito'}
                          </ItemValue>
                        </SummaryItem>
                        <SummaryItem small>
                          <ItemTitle>Bandeira</ItemTitle>
                          <ItemValue>{nextPaymentData.cardFlag}</ItemValue>
                        </SummaryItem>
                        <SummaryItem>
                          <ItemTitle>Final do cartão</ItemTitle>
                          <ItemValue>
                            {this.parseFinalNumber(nextPaymentData.finalNumber)}
                          </ItemValue>
                        </SummaryItem>
                        <SummaryItem small>
                          <ItemTitle>Validade</ItemTitle>
                          <ItemValue>{nextPaymentData.validThru}</ItemValue>
                        </SummaryItem>
                      </SummaryContainer>
                    ) : (
                      <SummaryContainer>
                        <SummaryItem>
                          <ItemTitle>Forma de pagamento</ItemTitle>
                          <ItemValue>Débito em conta</ItemValue>
                        </SummaryItem>
                        <SummaryItem small>
                          <ItemTitle>Agência</ItemTitle>
                          <ItemValue>{nextPaymentData.agency}</ItemValue>
                        </SummaryItem>
                        <SummaryItem>
                          <ItemTitle>Banco</ItemTitle>
                          <ItemValue>{nextPaymentData.bankCode}</ItemValue>
                        </SummaryItem>
                        <SummaryItem small>
                          <ItemTitle>Conta</ItemTitle>
                          <ItemValue>{nextPaymentData.account}</ItemValue>
                        </SummaryItem>
                      </SummaryContainer>
                    )}

                    <ButtonsContainer>
                      <Button
                        text="Cancelar"
                        buttonType="white"
                        disabled={loading}
                        onClick={() => this.close()}
                      />
                      <Button
                        text={loading ? 'Salvando' : 'Confirmar'}
                        disabled={loading}
                        loading={loading}
                        onClick={
                          currentPaymentType === 'CARTAO_DE_CREDITO'
                            ? () => this.onConfirmClick()
                            : this.onConfirmClickDebito()
                        }
                      />
                    </ButtonsContainer>
                  </ModalContent>
                </ModalContainer>
              )}
            </Content>
          </Fade>
          <Overlay
            onClick={() => (requested ? this.onSuccessChangesClick() : this.close())}
            modalBlue={modalBlue}
          />
        </Wrapper>
      </Fade>
    )
  }
}

export class SimpleModal extends Component {
  state = { isOpen: true }

  close = () => {
    this.setState({ isOpen: !this.state.isOpen })
    setTimeout(() => {
      this.props.hideModal()
    }, 400)
  }

  render() {
    const { isOpen } = this.state
    const { children } = this.props
    return (
      <Fade isOpen={isOpen}>
        <Wrapper>
          <Fade isOpen={isOpen}>
            <Content maxWidth={400}>
              <ModalSimpleContainer>{children}</ModalSimpleContainer>
            </Content>
          </Fade>
        </Wrapper>
      </Fade>
    )
  }
}

Modal.defaultProps = {
  title: null,
  onConfirm: null,
  hideModal: null
}

Modal.propTypes = {
  title: string,
  onConfirm: func,
  hideModal: func
}

export default Modal
