import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { Flex, Box } from 'grid-styled'
import { withRouter } from 'react-router-dom'
import { utils } from 'alelo-logic'

import TextInput from 'app/elements/TextInput'
import Button from '../../elements/Button'
import Radio from '../../elements/Radio'

import { ParagraphGrayLabel } from '../../pages/BlockIdentifiers/styles'

const { buttons } = require('./data.json')

const renderRadio = ({ input, label }) => <Radio {...input} label={label} />

const renderOtherMotive = ({ placeholder, input, onChange, value, meta: { touched, error } }) => (
  <div>
    <ParagraphGrayLabel>DESCREVA O MOTIVO</ParagraphGrayLabel>
    <TextInput
      {...input}
      placeholder={placeholder}
      maxlength="40"
      lengthCounter
      errorMessage={touched && error}
    />
  </div>
)

const BlockIdentifiersForm = props => {
  const { handleSubmit, pristine, history, reasonList, handleChangeReason, reasonSelected } = props

  return (
    <form onSubmit={handleSubmit}>
      {reasonList &&
        reasonList.map(item => (
          <Box mb={24} key={item.chave}>
            <Field
              component={renderRadio}
              label={item.descricao}
              name="option"
              value={item.chave}
              type="radio"
              onChange={evt => {
                handleChangeReason(evt.target.value)
                window.dataLayer = window.dataLayer || []
                window.dataLayer.push({
                  event: 'event',
                  eventAction: `clique:${item.descricao}`,
                  eventCategory: 'veloe:area-logado:meus-produtos:bloquear-substituir',
                  eventLabel: item.descricao,
                  dimension24: item.descricao
                })
              }}
              data-dynatrace-id={`radio-${item.chave.toLowerCase()}`}
            />
          </Box>
        ))}
      {reasonSelected === 'OUTRO' && (
        <Box mb={20}>
          <Field
            component={renderOtherMotive}
            placeholder="Digite aqui"
            name="descricaoOutroProblema"
            validate={utils.emptyFieldValidate}
            isRequired
            data-dynatrace-id="input-outro-motivo"
          />
        </Box>
      )}
      <Flex wrap mt={60}>
        <Box width={[1, 'auto']}>
          <Button
            buttonType="white"
            onClick={() => {
              history.goBack()
              window.dataLayer = window.dataLayer || []
              window.dataLayer.push({
                event: 'event',
                eventAction: 'clique:voltar',
                eventCategory: 'veloe:area-logado:meus-produtos:bloquear-substituir',
                eventLabel: 'voltar'
              })
            }}
            text={buttons.back}
          />
        </Box>
        <Box
          width={[1, 'auto']}
          ml={[0, 30]}
          mt={[20, 0]}
          onClick={() => {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
              event: 'event',
              eventAction: 'clique:continuar',
              eventCategory: 'veloe:area-logado:meus-produtos:bloquear-substituir',
              eventLabel: 'continuar'
            })
          }}
        >
          <Button disabled={pristine} 
          type="submit" 
          text={buttons.next} 
          data-dynatrace-id="button-continuar"
          />
        </Box>
      </Flex>
    </form>
  )
}

export default reduxForm({
  form: 'formBlockChangeidentifier',
  destroyOnUnmount: true
})(withRouter(BlockIdentifiersForm))
