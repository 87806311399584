import React, { Component, cloneElement } from 'react'
import { func } from 'prop-types'
import { Transition } from 'react-transition-group'
import QrCode from 'qrcode.react'
import Button from '../Button/Button'
// import Icon from 'app/elements/Icon/Icon'
import { Box } from 'grid-styled'
import {
  Content,
  ModalContainer,
  ModalContent,
  Overlay,
  Wrapper,
  QrCodeDescription
  // Notice,
  // NoticeContent,
  // NoticeText
} from './styles'

// import data from './data.json'

const Fade = ({ isOpen, children }) => (
  <Transition in={isOpen} timeout={400}>
    {state => cloneElement(children, { state })}
  </Transition>
)
class Modal extends Component {
  state = { isOpen: true }

  close = () => {
    this.setState({ isOpen: false })
    setTimeout(() => {
      this.props.onClose()
    }, 300)
  }

  generateQrCodeValue = () => {
    try {
    const { userInfo, convenio } = this.props

    if (!userInfo || !userInfo.profile || !userInfo.profile.customerId) {
      throw new Error("Informações do usuário estão incompletas.");
    }

    return JSON.stringify({
      TRANSACAO: 'INCLUSAO DE AUTORIZACAO DE DEBITO AUTOMATICO',
      VERSAO: '1',
      BANCOS: [
        {
          BANCO: '001',
          PARAMETROS: {
            CONVENIO: convenio || '',
            IDENTIFICADOR: userInfo.profile.customerId
          },
        },
      ],
    });
  } catch (error) {
    console.error("Erro ao gerar o QR Code:", error.message);
    alert("Erro ao gerar o QR Code. Verifique os dados fornecidos.");
    return null;
  }
};

  render() {
    // const { nextPaymentInfo } = this.props
    const { isOpen } = this.state
    return (
      <Fade isOpen={isOpen}>
        <Wrapper>
          <Fade isOpen={isOpen}>
            <Content maxWidth={'540'}>
              <ModalContainer>
                <ModalContent center noPadding>
                  <QrCodeDescription>
                    Escaneie com o aplicativo do Banco do Brasil para autorizar o seu débito em
                    conta
                  </QrCodeDescription>
                  <Box mb={'1rem'}>
                    {isOpen && this.generateQrCodeValue() && (
                      <QrCode height={200} width={200} value={this.generateQrCodeValue()} />
                    )}
                  </Box>
                  {/* <Box width={[1, 420]} mb={30}>
                    <Notice>
                      <Icon height={28} icon="info-purple" viewBox="0 0 24 22" />
                      <NoticeContent>
                        <NoticeText>A sua fatura atual já está fechada.</NoticeText>
                        <NoticeText>
                          As alterações estão previstas para a fatura com vencimento no mês de{' '}
                          {nextPaymentInfo.mesAlteracao}.
                        </NoticeText>
                      </NoticeContent>
                    </Notice>
                  </Box> */}
                  <Button text="Confirmar" onClick={this.close} />
                </ModalContent>
              </ModalContainer>
            </Content>
          </Fade>
          <Overlay onClick={this.close} modalBlue />
        </Wrapper>
      </Fade>
    )
  }
}

Modal.defaultProps = {
  hideModal: null
}

Modal.propTypes = {
  hideModal: func
}

export default Modal
