import React from 'react'
import { compact } from 'lodash'

import CarouselBase from '@hugeinc/carousel/dist/carousel.cjs'
import { Wrapper, Navigator, NavigatorButton } from './styles'

const AUTO_SLIDE_TIMEOUT = 5000

class InfoCarouselWrapper extends React.Component {
  state = {
    activeSlide: null
  }

  componentDidMount() {
    if (typeof window === 'undefined') {
      return
    }

    if (!this.carousel) {
      this.mountCarousel()
    }

    this.scheduleSlideNext()
  }

  componentWillUnmount() {
    if (this.carouselTimeout) {
      clearTimeout(this.carouselTimeout)
    }
  }

  mountCarousel = () => {
    const options = {
      onSlide: to => this.moveCarousel(to),
      activeClass: 'active',
      slideWrap: 'ul',
      slides: 'li',
      infinite: false,
      display: 1,
      disableDragging: false,
      initialIndex: 0
    }

    if (this._carousel) {
      this.carousel = new CarouselBase(this._carousel, options)
      this.setState({
        startedCarousel: true,
        activeSlide: this.carousel.current
      })
    } else {
      console.warn('_carousel não está definido.');
    }
  }

  scheduleSlideNext = () => {
    if (this.carouselTimeout) {
      clearTimeout(this.carouselTimeout)
    }

    this.carouselTimeout = setTimeout(this.slideNext, AUTO_SLIDE_TIMEOUT)
  }

  slideNext = () => {
    const { activeSlide } = this.state
    const slidesAmount = this.props.children.length
    this.moveCarousel((activeSlide + 1) % slidesAmount, true)
  }

  moveCarousel = (to, withTransition) => {
    if (withTransition) {
      this.carousel.go(to)
    }

    this.setState({ activeSlide: to })
    this.scheduleSlideNext()
  }

  registerCarouselRef = ref => {
    this._carousel = ref
  }

  render() {
    const listItems = compact(this.props.children)
    const { activeSlide } = this.state

    return (
      <div>
        <Wrapper className="carousel" innerRef={this.registerCarouselRef}>
          <ul className="wrap">
            {listItems.map((child, index) => (
              <li key={index}> {child} </li>
            ))}
          </ul>
        </Wrapper>

        <Navigator>
          {listItems.map((_, index) => (
            <NavigatorButton
              type="button"
              key={index}
              active={activeSlide === index}
              onClick={() => this.moveCarousel(index, true)}
            />
          ))}
        </Navigator>
      </div>
    )
  }
}

export default InfoCarouselWrapper
