import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { Box } from 'grid-styled'
import ChooseFilterType from '../../modules/ChooseFilterType'
import ChooseFilterMonth from '../../modules/ChooseFilterMonth'
import ChooseFilterVehicle from '../ChooseFilterVehicle'
import Button from '../../elements/Button'

import { ButtonContainer, FormTitle } from './styles'

class StatementsFilterForm extends Component {
  state = {}

  applyFilter = checkPlan =>
    checkPlan.id.toString() === this.props.accountPlans.activePlanId.toString()

  render() {
    const { handleSubmit, accountPlans } = this.props

    const currentPlan =
      accountPlans && accountPlans.plans && accountPlans.plans.length > 0
        ? accountPlans.plans.find(this.applyFilter)
        : null

    if (!currentPlan) {
      console.warn('currentPlan está vazio ou indefinido.')
    }
    // SEMIAUTOMATICO
    const { identifiers = [] } = currentPlan || {}
    const identifiersTypes = _.map(identifiers, 'identifierType')
    const semiautomaticoTypes = ['BAND', 'SMART_BAND']
    const isSemiautomatico = _.some(semiautomaticoTypes, t => _.includes(identifiersTypes, t))

    return (
      <form onSubmit={handleSubmit}>
        <Box mb={50}>
          <FormTitle>Escolha o que você quer ver.</FormTitle>
          <ChooseFilterType isSemiautomatico={isSemiautomatico} />
          <ChooseFilterMonth />
          {currentPlan.identifiers && currentPlan.identifiers.length > 1 && (
            <ChooseFilterVehicle
              vehicles={currentPlan.identifiers}
              dispatch={this.props.dispatch}
            />
          )}
          <ButtonContainer>
            <Button buttonType="darkBlue" type="submit" text="Filtrar extrato" />
          </ButtonContainer>
        </Box>
      </form>
    )
  }
}

export default reduxForm({
  form: 'statementsFilter',
  destroyOnUnmount: true
})(connect(state => ({ accountPlans: state.reducer.accountPlans }))(StatementsFilterForm))
