import React from 'react'
import Helmet from 'react-helmet'
import { Box } from 'grid-styled'
import { connect } from 'react-redux'
import {
  fetchReasonList,
  resetReasonList,
  fetchGetUser,
  fetchGetBusinessUser,
  fetchLogout
} from 'alelo-logic/actions'
import { withRouter } from 'react-router-dom'

import ImersiveHeader from '../../elements/ImersiveHeader'
import BlockIdentifiersForm from '../../modules/BlockIdentifiersForm'
import { setLoading } from '../../modules/PageLoader/actions'

import { Content, Heading, Subtitle, Paragraph, Wrapper } from './styles'

import { enviarEventoDynatrace } from '../../../alelo-logic/services/commonService'

const { helmetTitle, title, text, optionsSubtitle } = require('./data.json')

class BlockIdentifiers extends React.Component {
  state = {
    reasonList: [],
    reasonSelected: ''
  }

  componentWillUnmount() {
    this.props.resetReasonList()
  }

  async componentDidMount() {
    const {
      setLoading,
      getUser,
      match: {
        params: { id }
      }
    } = this.props
    const idAccount = id

    setLoading(true)
    await getUser(
      this.props.account.accountType,
      this.props.history,
      this.props.match.params.identifierId
    )
      .then(resp => {
        const [, reasons = []] = resp || []
        this.setState({ reasonList: reasons })
      })
      .catch(() => {
        this.props.history.push(`/minha-veloe/${idAccount}/identificadores`)
      })
  }

  handleSave = (values, history) => {
    const motivo = values.option; // capturar o motivo selecionado
    const idAccount = this.props.match.params.id
    const identifier = this.props.match.params.identifierId

    // enviar o evento Dynatrace com os dados adequados
    enviarEventoDynatrace({
      codigoIdentificador: identifier,
      codigoConta: idAccount,
      motivoInativacaoIdentificador: motivo,
      motivoInativacaoIdentificadorDescricao: this.state.reasonSelected.descricao
  });

    history.push(
      `/minha-veloe/${idAccount}/identificador/${identifier}/inativar/novo-identificador/?option=${
        values.option
      }${values.descricaoOutroProblema ? `&description=${values.descricaoOutroProblema}` : ''}`
    )
  }

  handleChangeReason = value => this.setState(() => ({ reasonSelected: value }))

  render() {
    const { history } = this.props
    const { reasonList, reasonSelected } = this.state
    return (
      <section>
        <ImersiveHeader
          text="Meus Produtos"
          back={() => {
            history.goBack()
            window.dataLayer.push({
              event: 'event',
              eventAction: 'clique:voltar',
              eventCategory: 'veloe:area-logado:meus-produtos:bloquear-substituir',
              eventLabel: 'voltar'
            })
          }}
          data-dynatrace-id="button-voltar"
        />
        <Content>
          <Box width={[1, 6 / 12]}>
            <Helmet title={helmetTitle} />
            <Heading>{title}</Heading>
            <section>
              <Paragraph>{text}</Paragraph>
            </section>
            <section>
              <Subtitle>{optionsSubtitle}</Subtitle>
              <Wrapper>
                <BlockIdentifiersForm
                  onSubmit={values => this.handleSave(values, history)}
                  reasonList={reasonList}
                  reasonSelected={reasonSelected}
                  handleChangeReason={this.handleChangeReason}
                  data-dynatrace-id="block-identifiers-options" // Identificador Dynatrace para opções
                />
              </Wrapper>
            </section>
          </Box>
        </Content>
      </section>
    )
  }
}

const mapStateToProps = ({ form, reducer, ...rest }) => ({
  form,
  account: reducer.account,
  user: reducer.user,
  accountPlans: reducer.accountPlans,
  reasonListBlock: reducer.reasonsListBlock.reasons
})

const mapDispatchToProps = dispatch => ({
  getUser: (userType, history, identifierId) => {
    if (userType === 'pf') {
      return Promise.all([dispatch(fetchGetUser()), dispatch(fetchReasonList(identifierId))])
        .then()
        .catch(resp => {
          if (resp && (resp.status === 401 || resp.status === 403)) {
            dispatch(fetchLogout())
            history.push('/conta/login')
          }
        })
        .finally(() => dispatch(setLoading(false)))
    } else {
      return Promise.all([dispatch(fetchGetBusinessUser()), dispatch(fetchReasonList())])
        .then()
        .catch(resp => {
          if (resp && (resp.status === 401 || resp.status === 403)) {
            dispatch(fetchLogout())
            history.push('/conta/login')
          }
        })
        .finally(() => dispatch(setLoading(false)))
    }
  },
  resetReasonList: () => {
    dispatch(resetReasonList())
  },
  setLoading: isLoading => {
    dispatch(setLoading(isLoading))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BlockIdentifiers))
