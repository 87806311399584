import React, { useEffect, useRef, useState } from 'react'
import { bool, func } from 'prop-types'
import CarouselBase from '@hugeinc/carousel/dist/carousel.cjs'
import { debounce, flatten } from 'lodash'
import '@hugeinc/carousel/src/carousel.css'

import { isMobile, isDesktop } from '../../../utils/mediaQuery'
import Icon from '../../elements/Icon/Icon'

import { CarouselWrapper, IconColor, Item, List, Navigator, NavigatorList, NavItem } from './styles'

function Carousel({ mobileOnly, onSlide, ...props }) {
  const [startedCarousel, setStartedCarousel] = useState(false)
  const [activeSlide, setActiveSlide] = useState()
  const carouselRef = useRef()
  const listRef = useRef()
  let carousel = null

  useEffect(() => {
    if (typeof window === 'undefined') {
      return
    }

    if (mobileOnly) {
      window.addEventListener('resize', resizeHandler)
      handleMobileCarousel()
    } else if (!carousel) {
      mountCarousel()
    }

    return () => {
      if (mobileOnly) {
        window.removeEventListener('resize', resizeHandler)
      }

      if (carousel) {
        carousel.destroy()
      }
    }
  }, [])

  const handleSlide = to => {
    setActiveSlide(to)

    if (onSlide) {
      onSlide()
    }
  }

  const mountCarousel = () => {
    const options = {
      onSlide: handleSlide,
      activeClass: 'active',
      slideWrap: 'ul',
      slides: 'li',
      infinite: false,
      display: 1,
      disableDragging: false,
      initialIndex: 0
    }

    if (carouselRef) {
      carousel = new CarouselBase(carouselRef, options)
      setStartedCarousel(true)
      setActiveSlide(carousel.current);
    } else {
      console.warn('carouselRef.current não está definido.');
    }
  }

  const handleMobileCarousel = debounce(
    () => {
      if (isMobile() && !carousel) {
        mountCarousel()
      }

      if (isDesktop() && carousel) {
        listRef.style.transform = 'none'
        carousel.destroy()
        setStartedCarousel(false)
      }
    },
    250,
    {
      maxWait: 1000
    }
  )

  const resizeHandler = () => {
    handleMobileCarousel()
  }

  const moveCarousel = to => {
    carousel.go(to)
  }

  const mountNavigator = (listItemsNavigator, hasArrow) => (
    <Navigator hasArrow={hasArrow}>
      {hasArrow && !isMobile() && (
        <Icon
          icon="left-arrow-large"
          svgStyle={IconColor}
          width="30"
          height="30"
          viewBox="0 0 70 70"
          onClick={() => moveCarousel(carousel.current - 1)}
        />
      )}
      <NavigatorList>
        {listItemsNavigator.map((child, index) => (
          <NavItem active={activeSlide === index} key={index} onClick={() => moveCarousel(index)} />
        ))}
      </NavigatorList>
      {hasArrow && !isMobile() && (
        <Icon
          icon="right-arrow-large"
          svgStyle={IconColor}
          width="30"
          height="30"
          viewBox="15 15 70 70"
          onClick={() => moveCarousel(carousel.current + 1)}
        />
      )}
    </Navigator>
  )

  const handleMountNavigator = listItemsHandleNavigator => {
    if ((isMobile() && props.hasMobileNavigation) || props.hasNavigation)
      return mountNavigator(listItemsHandleNavigator, props.hasArrow)
  }

  const { children, flexibleWidth, fullWidth } = props

  const listItems = flatten(children).filter(child => child)

  return (
    <CarouselWrapper
      mobileOnly={mobileOnly}
      className="carousel"
      startedCarousel={startedCarousel}
      fullWidth={fullWidth}
      innerRef={carouselRef}
    >
      <List className="wrap" innerRef={listRef}>
        {listItems.map((child, index) => (
          <Item flexibleWidth={flexibleWidth} key={index}>
            {child}
          </Item>
        ))}
      </List>
      {handleMountNavigator(listItems)}
    </CarouselWrapper>
  )
}

Carousel.defaultProps = {
  onSlide: null,
  mobileOnly: true,
  fullWidth: true
}

Carousel.propTypes = {
  onSlide: func,
  mobileOnly: bool
}

export default Carousel
