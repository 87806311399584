import React, { cloneElement, useState } from 'react'
import { Transition } from 'react-transition-group'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import truncante from '../../../utils/truncate'
import currency from '../../../utils/currency'
import {
  Overlay,
  Content,
  Wrapper,
  ModalContainer,
  ModalContent,
  Banner,
  CloseIcon,
  TextContent,
  ValuesWrapper,
  CopySlipBankCode,
  ButtonWrapper,
  Button
} from './styles'

const Fade = ({ isOpen, children }) => (
  <Transition in={isOpen} timeout={400}>
    {state => cloneElement(children, { state })}
  </Transition>
)

const BANNER = require('../../../static/bankSlipModal.png').default
const CLOSE_ICON = require('../../../static/close.png').default
const COPY_ICON = require('../../../static/copyIcon.png').default

const BankSlipModal = ({ bankSlipData, onClose }) => {
  if (!bankSlipData || !bankSlipData.boleto || !bankSlipData.boleto.codigo) {
    console.error("Dados do boleto estão incompletos.");
    alert("Erro: Informações do boleto estão ausentes. Verifique e tente novamente.");
    return null;
  }
  const {
    boleto: { codigo, link },
    dataVencimento,
    valores: { cobranca, desconto, juros, multa, original }
  } = bankSlipData
  const [isOpen, setIsOpen] = useState(true)
  const [bankSlip, setBankSlip] = useState('')

  return (
    <Fade isOpen={isOpen}>
      <Wrapper>
        <Fade isOpen={isOpen}>
          <Content maxWidth={'864'}>
            <ModalContainer>
              <ModalContent center noPadding>
                <Banner>
                  <CloseIcon
                    src={CLOSE_ICON}
                    alt="ícone em formato de um X"
                    onClick={() => {
                      setIsOpen(false)
                      if(onClose) onClose()
                    }}
                  />
                  <img
                    src={BANNER}
                    alt="ícone demonstrando uma mão segurando um celular com aplicativo aberto demonstrando a brasão da marca veloe"
                  />
                </Banner>
                <TextContent>
                  <h1>Boleto gerado com sucesso!</h1>
                  <p>
                    Seu boleto já está disponivel para pagamento. Utilize o código de barras
                    <CopyToClipboard text={codigo}>
                      <CopySlipBankCode title="Copiar">
                        <b>
                          {` ${truncante(`${codigo}`, 15)}`}
                          <img
                            src={COPY_ICON}
                            alt="ícone de duas folhas, sendo uma sobre a outra, indicando a cópia do código"
                          />
                        </b>
                      </CopySlipBankCode>
                    </CopyToClipboard>
                    e pague agora mesmo. O boleto pode levar até 2 dias úteis para ser compensado.
                  </p>
                  <ValuesWrapper>
                    <p>
                      <span>{`Data de vencimento: `}</span>
                      {`${dataVencimento
                        .split('-')
                        .reverse()
                        .join('/')}`}
                    </p>
                    <p>
                      <span>{`Valor das faturas sem multa: `}</span>
                      {currency(original)}
                    </p>
                    <p>
                      <span>{`Valor de juros: `}</span>
                      {currency(juros)}
                    </p>
                    <p>
                      <span>{`Valor de multas: `}</span>
                      {currency(multa)}
                    </p>
                    <p>
                      <span>{`Valor de desconto: `}</span>
                      {currency(desconto)}
                    </p>
                    <p>
                      <span>{`Valor Final: `}</span>
                      {currency(cobranca)}
                    </p>
                  </ValuesWrapper>
                  <ButtonWrapper>
                    <Button
                      onClick={() => {
                        setBankSlip(link)
                      }}
                    >
                      Baixar Boleto em pdf
                    </Button>
                    <Button
                      onClick={() => {
                        setIsOpen(false)
                        onClose()
                      }}
                      secondary
                    >
                      Ok
                    </Button>
                  </ButtonWrapper>
                  {bankSlip && (
                    <iframe
                      title="BankSlipDownload"
                      src={`${bankSlip}`}
                      style={{ display: 'none' }}
                    />
                  )}
                </TextContent>
              </ModalContent>
            </ModalContainer>
          </Content>
        </Fade>
        <Overlay onClick={() => setIsOpen(false)} modalBlue />
      </Wrapper>
    </Fade>
  )
}

export default BankSlipModal
