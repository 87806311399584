import { getPlanData } from '../services/dashboardService'

import types from './types'

const { UPDATE_PLAN_DATA } = types

export default function fetchPlanData(plan) {
  if (!plan || !plan.id) {
    console.warn('Plano não informado ou inválido.')
    return Promise.reject(new Error('Plano inválido.'))
  }

  return dispatch =>
    getPlanData(plan)
      .then(plandata => {
        dispatch({
          type: UPDATE_PLAN_DATA,
          plandata: plandata
        })
      })
      .catch(error => {
        console.error('Erro em #fetchPlanData()', error)
        return error
      })
}
