import React, { Component, cloneElement } from 'react'
import { string, func } from 'prop-types'
import { Transition } from 'react-transition-group'
import { Box } from 'grid-styled'
import { Icon } from '../../elements/Icon'
import Button from '../Button/Button'
import Hide from '../../../styles/utilities/Hide'

import {
  Close,
  Content,
  ModalSimpleContainer,
  ModalContainer,
  ModalContent,
  ModalDescription,
  ModalIllustration,
  ModalTitle,
  Overlay,
  Wrapper
} from './styles'

const data = require('./data.json')

const Fade = ({ isOpen, children }) => (
  <Transition in={isOpen} timeout={400}>
    {state => cloneElement(children, { state })}
  </Transition>
)
class Modal extends Component {
  state = { isOpen: true }

  close = () => {
    this.setState({ isOpen: !this.state.isOpen })
    setTimeout(() => {
      if (typeof this.props.hideModal === 'function') {
        this.props.hideModal()
      } else {
        console.warn('hideModal não é uma função válida.')
      }
    }, 400)
  }

  render() {
    const {
      description,
      image,
      title,
      children,
      compact,
      background,
      modalBlue,
      maxWidth,
      noPadding,
      accountType,
      confirmButtonLabel
    } = this.props

    return (
      <Fade isOpen={this.state.isOpen}>
        <Wrapper>
          <Fade isOpen={this.state.isOpen}>
            <Content maxWidth={maxWidth}>
              <ModalContainer background={background}>
                <ModalContent compact={compact} noPadding={noPadding}>
                  {title && (
                    <Box mb={['2.188rem', compact ? '1.5rem' : '3.125rem']}>
                      <ModalTitle compact={compact}>{title}</ModalTitle>
                    </Box>
                  )}
                  {description && (
                    <Box mb={['2.188rem', '3.125rem']}>
                      <ModalDescription>{description}</ModalDescription>
                    </Box>
                  )}
                  {children && <div>{children}</div>}
                  {image && (
                    <Hide className="feedback-image" at="large" mb="2.813rem">
                      <ModalIllustration src={image} />
                    </Hide>
                  )}
                  {this.props.onConfirm && (
                    <Button
                      buttonType="transparent"
                      text={confirmButtonLabel || data.confirmBtn}
                      onClick={this.props.onConfirm}
                    />
                  )}
                </ModalContent>
                {image && (
                  <Hide at="small">
                    <ModalIllustration accountType={accountType} src={image} />
                  </Hide>
                )}
                <Close onClick={() => this.close()}>
                  <Icon icon="modal-close" height="1.125rem" width="1.125rem" viewBox="0 0 21 21" />
                </Close>
              </ModalContainer>
            </Content>
          </Fade>
          <Overlay onClick={() => this.close()} modalBlue={modalBlue} />
        </Wrapper>
      </Fade>
    )
  }
}

export class SimpleModal extends Component {
  state = { isOpen: true }

  close = () => {
    this.setState({ isOpen: !this.state.isOpen })
    setTimeout(() => {
      if (typeof this.props.hideModal === 'function') {
        this.props.hideModal()
      } else {
        console.warn('hideModal não é uma função válida.')
      }
    }, 400)
  }

  render() {
    const { isOpen } = this.state
    const { children } = this.props
    return (
      <Fade isOpen={isOpen}>
        <Wrapper>
          <Fade isOpen={isOpen}>
            <Content maxWidth={400}>
              <ModalSimpleContainer>{children}</ModalSimpleContainer>
            </Content>
          </Fade>
        </Wrapper>
      </Fade>
    )
  }
}

Modal.defaultProps = {
  title: null,
  onConfirm: null,
  hideModal: null
}

Modal.propTypes = {
  title: string,
  onConfirm: func,
  hideModal: func
}

export default Modal
