import {
  getAccountPlans,
  getECAccountPlans,
  postCancellationRequest,
  putCancellationRequest
} from '../services/accountService'
import Storage from '../services/common/storages/tokenStorage'

import types from './types'

const {
  SET_ACCOUNT_PLANS,
  SET_EC_ACCOUNT_PLANS,
  SET_ACCOUNT_PLAN,
  SET_ACCOUNT_ACTIVE_PLAN_ID,
  SET_CANCEL_ACCOUNT_STATUS
} = types

const storage = new Storage()

export function fetchAccountPlans() {
  const accountId = storage.getAccount()?.id

  return async dispatch => {
    try {
      const plans = await getAccountPlans(accountId)
      const newPlans =
        plans && plans.length
          ? plans.map(pl => ({
              ...pl,
              paymentType:
                pl.paymentType === 'SEM_DADOS_PAGAMENTO' || !pl.paymentType ? null : pl.paymentType
            }))
          : []

      dispatch({
        type: SET_ACCOUNT_PLANS,
        plans: newPlans
      })

      return newPlans
    } catch (error) {
      console.error('Erro ao buscar os planos da conta:', error)
    }
  }
}

export function fetchECAccountPlans() {
  const accountId = storage.getAccount().id

  return dispatch =>
    getECAccountPlans(accountId).then(plans => {
      dispatch({
        type: SET_EC_ACCOUNT_PLANS,
        plans
      })

      return plans
    })
}

export function fetchAccountPlan(planId) {
  const accountId = storage.getAccount().id

  return dispatch =>
    getAccountPlans(accountId, planId).then(plan => {
      dispatch({
        type: SET_ACCOUNT_PLAN,
        plan
      })

      return plan
    })
}

export function setActivePlanId(activePlanId) {
  return {
    type: SET_ACCOUNT_ACTIVE_PLAN_ID,
    activePlanId
  }
}

export function fetchPostCancellationRequest(accountId, planId, motive) {
  return dispatch =>
    postCancellationRequest(accountId, planId, motive).then(resp => {
      dispatch({
        type: SET_CANCEL_ACCOUNT_STATUS,
        payload: {
          waitingCancellationToken: true
        }
      })

      return resp
    })
}

export function fetchPutCancellationRequest(accountId, planId, token) {
  return dispatch =>
    putCancellationRequest(accountId, planId, token).then(resp => {
      dispatch({
        type: SET_CANCEL_ACCOUNT_STATUS,
        payload: {
          waitingCancellationToken: false,
          protocol: resp.protocol,
          date: resp.date
        }
      })

      return resp
    })
}
