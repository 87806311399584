import React, { Component, cloneElement } from 'react'
import { string, func } from 'prop-types'
import { Transition } from 'react-transition-group'
import { Icon } from '../../elements/Icon'
import Button from '../Button/Button'

import disponivelAppStore from '../../../static/disponivel-na-app-store.png'
import disponivelPlayStore from '../../../static/disponivel-na-play-store.png'

import {
  Close,
  Content,
  ModalSimpleContainer,
  ModalContainer,
  ModalIllustration,
  Overlay,
  Wrapper,
  JaBaixeiButton,
  ModalAppStore,
  ModalPlayStore
} from './styles'

const data = require('./data.json')

const Fade = ({ isOpen, children }) => (
  <Transition in={isOpen} timeout={400}>
    {state => cloneElement(children, { state })}
  </Transition>
)
class Modal extends Component {
  state = { isOpen: !localStorage.getItem('jaBaixei') }

  close = () => {
    this.setState({ isOpen: !this.state.isOpen })
    setTimeout(() => {
      if (typeof this.props.hideModal === 'function') {
        this.props.hideModal();
      } else {
        console.warn('hideModal não está definido ou não é uma função válida.');
      }
    }, 400);
  }

  jaBaixei = () => {
    this.setState({ isOpen: !this.state.isOpen })
    localStorage.setItem('jaBaixei', 'true')
    setTimeout(() => {
      if (typeof this.props.hideModal === 'function') {
        this.props.hideModal();
      } else {
        console.warn('hideModal não está definido ou não é uma função válida.');
      }
    }, 400);
  }

  render() {
    const { image, background, modalBlue, maxWidth, confirmButtonLabel } = this.props

    return (
      <Fade isOpen={this.state.isOpen}>
        <Wrapper>
          <Fade isOpen={this.state.isOpen}>
            <Content maxWidth={maxWidth}>
              <ModalContainer background={background}>
                {image && <ModalIllustration src={image} />}
                {this.props.onConfirm && (
                  <Button
                    buttonType="transparent"
                    text={confirmButtonLabel || data.confirmBtn}
                    onClick={this.props.onConfirm}
                  />
                )}
                <Close onClick={() => this.close()}>
                  <Icon icon="modal-close" height="1.125rem" width="1.125rem" viewBox="0 0 21 21" />
                </Close>
                <ModalAppStore
                  onClick={() =>
                    window.open('https://apps.apple.com/br/app/veloe/id1278343398', '_blank')
                  }
                  src={disponivelAppStore}
                />
                <ModalPlayStore
                  onClick={() =>
                    window.open(
                      'https://play.google.com/store/apps/details/?id=br.com.veloe.mobile',
                      '_blank'
                    )
                  }
                  src={disponivelPlayStore}
                />
                <JaBaixeiButton onClick={() => this.jaBaixei()}>JÁ BAIXEI!</JaBaixeiButton>
              </ModalContainer>
            </Content>
          </Fade>
          <Overlay onClick={() => this.close()} modalBlue={modalBlue} />
        </Wrapper>
      </Fade>
    )
  }
}

export class SimpleModal extends Component {
  state = { isOpen: true }

  close = () => {
    this.setState({ isOpen: !this.state.isOpen })
    setTimeout(() => {
      this.props.hideModal()
    }, 400)
  }

  render() {
    const { isOpen } = this.state
    const { children } = this.props
    return (
      <Fade isOpen={isOpen}>
        <Wrapper>
          <Fade isOpen={isOpen}>
            <Content maxWidth={400}>
              <ModalSimpleContainer>{children}</ModalSimpleContainer>
            </Content>
          </Fade>
        </Wrapper>
      </Fade>
    )
  }
}

Modal.defaultProps = {
  title: null,
  onConfirm: null,
  hideModal: null
}

Modal.propTypes = {
  title: string,
  onConfirm: func,
  hideModal: func
}

export default Modal
