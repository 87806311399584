import React, { Component } from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Box } from 'grid-styled'
import { withRouter } from 'react-router-dom'
import {
  fetchAccountPlan,
  fetchPostCancellationRequest,
  fetchPutCancellationRequest
} from 'alelo-logic/actions'

import { INPUT_MODAL } from '../../../constants/ModalTypes'
import { showModal } from '../../modules/ModalRoot/actions'
import image from '../../../static/mail-to.png'

import ImersiveHeader from '../../elements/ImersiveHeader'
import CancelAccountForm from '../../modules/CancelAccountForm'

import { Content, Heading, Subtitle, Label, Wrapper } from './styles'

const data = require('./data.json')

class CancelPlanMotive extends Component {
  async componentWillMount() {
    await this.props.getCurrentPlan(this.props.match.params.id)
  }

  render() {
    const { match, account, accountPlans, ...props } = this.props
    const currentPlan = accountPlans && accountPlans.plan

    let planFullName = ''

    if (currentPlan) {
      planFullName = `${currentPlan.plan.category || ''} ${data.planType[currentPlan.plan.type] ||
        ''}`
    } else {
      console.warn('currentPlan está indefinido.')
    }

    return (
      <section>
        <ImersiveHeader
          text="Dados do plano"
          url={`/minha-veloe/${match.params.id}/dados-do-plano`}
        />

        <Content>
          <Box width={[1, 6 / 12]}>
            <Helmet title={data.helmetTitle} />
            <Label>{data.label}</Label>
            <Heading>{planFullName}</Heading>
            <section>
              <Subtitle>{data.optionsSubtitle}</Subtitle>
              <Wrapper>
                <CancelAccountForm
                  ctaText="Cancelar plano"
                  form="cancelPlanMotive"
                  onSubmit={values =>
                    props.fetchPostCancellationRequest(account.id, match.params.id, values)
                  }
                />
              </Wrapper>
            </section>
          </Box>
        </Content>
      </section>
    )
  }
}

const mapStateToProps = ({ reducer }) => ({
  accountPlans: reducer.accountPlans,
  account: reducer.account
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getCurrentPlan(planId) {
    return dispatch(fetchAccountPlan(planId))
  },
  fetchPostCancellationRequest(accountId, planId, motive) {
    return dispatch(fetchPostCancellationRequest(accountId, planId, motive)).then(() => {
      this.showTokenModal(accountId, planId)
    })
  },
  showTokenModal(accountId, planId, motive) {
    dispatch(
      showModal(INPUT_MODAL, {
        ...data.modal,
        fetchSendProvider: token => dispatch(fetchPutCancellationRequest(accountId, planId, token)),
        fetchRefreshProvider: () => this.fetchPostCancellationRequest(accountId, planId, motive),
        onConfirm: () => {
          const params = ownProps.match.params
          ownProps.history.push(`/minha-veloe/${params.id}/cancelar-plano/confirmacao`)
        },
        image
      })
    )
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CancelPlanMotive))
