import { gqlQuery } from './common/apolloClient'
import { gqlTiposTelefone, transformTelefones } from './queries/telephone'
import { gqlReasons } from './queries/vehicles'
import { gqlGetCitiesFromState, gqlSearchAddress, gqlGetStates } from './queries/address'
import { gqlSubsidyMessage } from './queries/plans'
import {
  gqlEstadosCivis,
  transformEstadosCivis,
  gqlSexos,
  transformSexos,
  gqlParametrizacao,
  transformBancos
} from './queries/domain'

// TODO Migrar para serviço graphQL
export async function getBankList() {
  const resp = await gqlQuery(gqlParametrizacao, { parametro: 'bancos' })
  return transformBancos(resp)
  // return [
  //   { value: 1, label: '001 - Banco do Brasil S.A.' },
  //   { value: 33, label: '033 - Banco Santander (Brasil) S.A.' },
  //   { value: 104, label: '104 - Caixa Econômica Federal' },
  //   { value: 237, label: '237 - Banco Bradesco S.A.' },
  //   { value: 341, label: '341 - Itaú Unibanco S.A.' }
  // ]
}

export async function getDeadline() {
  const resp = await gqlQuery(gqlParametrizacao, { parametro: 'prazoEntrega' })

  if (resp && resp.data && resp.data.parametrizacao && resp.data.parametrizacao.length > 0) {
    return {
      key: resp.data.parametrizacao[0].chave,
      value: resp.data.parametrizacao[0].valor,
      description: resp.data.parametrizacao[0].descricao
    }
  } else {
    return {
      key: '',
      value: '',
      description: '5 dias úteis'
    }
  }
}

const DYNATRACE_URL = 'https://veloehml.live.dynatrace.com/api/v2/bizevents/ingest';
const DYNATRACE_TOKEN = 'dt0c01.T5Q5QKPYYH4DR4MXDXYJ2MV6.YKW5OSEWMXVZ52Y7AKI3LVHZAQVQJ5ZPT2BNULRMAKXVO27K4W226IIUXRJWRP7U';

// enviar evento dynatrace - motivoselecionado
export async function enviarEventoDynatrace(payload) {
  try {
    const response = await fetch(DYNATRACE_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/cloudevents+json',  // ajuste baseado na documentação
        'Authorization': `Api-Token ${DYNATRACE_TOKEN}` // lembrar de incluir o token de autenticação correto
      },
      body: JSON.stringify({
        specversion: "1.0",
        id: "1", // pode ser ajustado para um valor único conforme a necessidade
        source: "custom.source",
        type: "com.veloe.inativacao.tags",
        dtcontext: `dt.session_id="234234234324235345345",dt.entity.rum_application="APPLICATION-53453458340758",host.name="123.123.123.123"`,
        dataschema: "http://dynatrace.com/schema/bizevents/generic/1.0",
        traceparent: "00-4bf92f3577b34da6a3ce929d0e0e4736-00f067aa0ba902b7-00",
        data: {
          id: payload.codigoIdentificador,
          codigoConta: payload.codigoConta,
          motivoInativacaoIdentificador: payload.motivoInativacaoIdentificador,
          motivoInativacaoIdentificadorDescricao: payload.motivoInativacaoIdentificadorDescricao,
          eventId: `event-${payload.motivoInativacaoIdentificador}` // Novo atributo para identificar a origem do evento
        }
      })
    });

    if (!response.ok) {
      console.error('Falha ao enviar evento para o Dynatrace', response.statusText);
    }
  } catch (error) {
    console.error('Erro ao enviar evento para o Dynatrace:', error);
  }
}

export async function getTelephoneTypesList() {
  const telefones = await gqlQuery(gqlTiposTelefone)
  return transformTelefones(telefones)
}

export async function getCitiesFromState(data) {
  const payload = { estado: data.state }
  const response = await gqlQuery(gqlGetCitiesFromState, payload)
  return response.data.cidades
}

export async function zipCodeGet(data) {
  const payload = { cep: data.zip_code }
  const response = await gqlQuery(gqlSearchAddress, payload)
  return response.data.cep
}

const reasonsListFallback = [
  {
    chave: 'PARABRISA_QUEBRADO',
    cobrar: false,
    descricao: 'Parabrisa quebrado'
  },
  {
    chave: 'ERRO_LEITURA_TAG',
    cobrar: false,
    descricao: 'Erro de leitura do produto'
  },
  {
    chave: 'EXTRAVIO_TAG',
    cobrar: true,
    descricao: 'Perdi o produto',
    valor: '34.9'
  },
  {
    chave: 'ROUBO_VEICULO',
    cobrar: false,
    descricao: 'Fui roubado'
  },
  {
    chave: 'VENDA_VEICULO',
    cobrar: true,
    descricao: 'Venda do veículo',
    valor: '34.9'
  },
  {
    chave: 'OUTRO',
    cobrar: true,
    descricao: 'Outro motivo',
    valor: '34.9'
  }
]

export async function getReasonList(codIdentificador) {
  const payload = { codIdentificador }
  const { data: { identificadorMotivoBloqueio = null } = {} } = await gqlQuery(gqlReasons, payload)
  const list = identificadorMotivoBloqueio || reasonsListFallback
  return list
}

export async function getStates() {
  const response = await gqlQuery(gqlGetStates)
  return response.data.estados
}

export async function getGenderList() {
  const resp = await gqlQuery(gqlSexos)
  return transformSexos(resp)
}

export async function getMaritialStatusList() {
  const resp = await gqlQuery(gqlEstadosCivis)
  return transformEstadosCivis(resp)
}

export async function getSubsidyMessage(idConta) {
  const idCliente = sessionStorage.getItem('customerId')
  const payload = { idConta: idConta, idCliente: idCliente }
  const response = await gqlQuery(gqlSubsidyMessage, payload)
  return response.data.mensagemSubsidio
}
