// import { apiURLVeloe, apiAuthorization } from '../../../config.json'

import config from '../../config.json'
// import { apiURL, publicSite } from '../../config'
const {
  apiURL,
  publicSite,
  apiURLVeloe,
  apiAuthorization,
  brasPagURL,
  buildNumber,
  authScope,
  brasPagVerifyCardURL,
  brasPagVerifyCardProvider,
  merchantId,
  merchantKey,
  baseVindiURL,
  vindiAuthorization
} = config

const requiredConfigs = { apiURLVeloe, apiAuthorization };
Object.entries(requiredConfigs).forEach(([key, value]) => {
  if (!value) console.warn(`Configuração ausente: ${key}`);
});

export const API_URL = `${apiURL}`
export const PUBLIC_SITE_URL = `${publicSite}`
export const BUILD_NUMBER = `build.${buildNumber}`
export const BRASPAG_URL = `${brasPagURL}`
export const BRASPAG_VERIFYCARD_URL = `${brasPagVerifyCardURL}`
export const BRASPAG_VERIFYCARD_PROVIDER = `${brasPagVerifyCardProvider}`
export const MERCHANT_ID = `${merchantId}`
export const MERCHANT_KEY = `${merchantKey}`
export const LOGOUT = '/auth/logout'
export const API_BRASPAG_CARD_URL = `${brasPagURL}/card`
export const API_BRASPAG_TOKEN_URL = `${apiURLVeloe}/ms-proxy/gateway/accesstoken`
export const AUTH_URL = `${apiURLVeloe}/auth/v1/authentication/token`
export const AUTH_SCOPE = authScope
export const API_AUTHORIZATION = apiAuthorization
export const GRAPHQL_URL = `${apiURLVeloe}/api/graphql`
// export const GRAPHQL_URL = `http://localhost:4002/local`
export const CHANGE_PASSWORD = `${apiURLVeloe}/auth/v1/alterar-senha`
export const GRAPHQL_MAX_RETY = 5
export const AJAX_MAX_RETRY = 5
export const VINDI_TOKEN_URL = `${baseVindiURL}/api/v1/public/payment_profiles`
export const VINDI_AUTHORIZATION = vindiAuthorization
