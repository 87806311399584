import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import { routerMiddleware, routerReducer } from 'react-router-redux'
import { reducer as reduxFormReducer } from 'redux-form'
import thunk from 'redux-thunk'
import createHistory from 'history/createBrowserHistory'

import reducer from './reducer'
import reduxPromiseMiddleware from './middlewares/reduxPromiseMiddleware'

import modalReducer from './app/modules/ModalRoot/reducer'
import stepBoxReducer from './app/modules/StepBox/reducer'
import tokenModalReducer from './app/modules/TokenModal/reducer'
import headerReducer from './app/modules/Header/reducer'
import pageLoader from './app/modules/PageLoader/reducer'

const appReducer = combineReducers({
  router: routerReducer,
  form: reduxFormReducer,
  reducer,
  tokenModal: tokenModalReducer,
  modalReducer,
  stepBox: stepBoxReducer,
  header: headerReducer,
  pageLoader
})

export const history = createHistory()

const initialState = {}
const enhancers = []
const middleware = [reduxPromiseMiddleware, thunk, routerMiddleware(history)]

const token = sessionStorage.getItem('token')

if (token && typeof token !== 'undefined') {
  try {
    const decodedToken = atob(token)
    initialState.reducer = {
      account: JSON.parse(decodedToken)
    }
  } catch (error) {
    console.warn('Erro ao decodificar ou parsear o token:', error)
  }
}

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    sessionStorage.clear()
    return appReducer(initialState, action)
  }

  return appReducer(state, action)
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers)

const store = createStore(rootReducer, initialState, composedEnhancers)

export default store
